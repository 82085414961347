import HelpIcon from '@mui/icons-material/Help';
import { Box, Chip, IconButton, Popover, Rating, Stack, Typography, useTheme } from "@mui/material";
import { useReadonlyState } from "../../hooks/useReadonlyState";
import { NPOWithId } from "../../models/Npo";
import { regionOrOtherToString } from "../../util/stringUtil";
import SubheadLink from '../Global/SubheadLink';

export default function NpoQuickDetails({
  npo,
}: {
  npo?: Readonly<NPOWithId> | NPOWithId | null,
}) {
  const theme = useTheme()
  const [popoverAnchorEl, setPopoverAnchorEl] = useReadonlyState<HTMLButtonElement | null>(null)

  const handleHelpClick = (event: React.MouseEvent<HTMLButtonElement>) => setPopoverAnchorEl(event.currentTarget)
  const handlePopoverClose = () => setPopoverAnchorEl(null)

  const openHelp = Boolean(popoverAnchorEl)
  const popoverId = openHelp ? 'rating-help-popover' : undefined

  if (!npo?.ein) return null

  return (<Box padding={1}>
    <Typography variant="bodyLarge">{npo.name}</Typography>
    <Typography>
      {regionOrOtherToString(npo.primaryRegion, npo.city, npo.state) ?? "Location unknown"}
    </Typography>
    <Stack direction="row" alignItems="center" spacing={1}>
      <Typography variant="caption">
        EIN: {npo.ein}
      </Typography>
      <Chip label={npo.primaryCause} size="small" />
    </Stack>
    {!!npo.charityNavEncompassScore && npo.charityNavEncompassScore > 0 && (
      <Box mt={1.5}>
        <Typography>
          Charity Navigator scoring
        <IconButton size='small' aria-describedby={popoverId} aria-label="learn more about this rating" onClick={handleHelpClick} >
          <HelpIcon />
        </IconButton>
        </Typography>
        <Popover
          open={openHelp}
          onClose={handlePopoverClose}
          id={popoverId}
          anchorEl={popoverAnchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Box pl={3} pt={2} pr={3} pb={3} maxWidth="550px">
            <Typography variant='h6' component='h4'>More about this rating</Typography>
            <Typography>The Encompass ratings are from Charity Navigator. From the website:</Typography>
            <Typography component="blockquote" margin={1.5} paddingLeft={1} borderLeft="2px solid #DDD" color={theme.palette.neutral.main}>
              Two objectives drive our approach to rating charities: helping donors and celebrating the work of charities.
              Drawing from a knowledge base of research, analysis, and constituent feedback, our team develops ratings that
              are both analytical and responsive to the data needs of our site users.
            </Typography>
            <Typography>
              <SubheadLink external to="https://www.charitynavigator.org/about-us/our-methodology/ratings/" direction='external' size='small'>
                Learn more about Charity Navigator&apos;s rating methodology.
              </SubheadLink>
            </Typography>
          </Box>
        </Popover>

        <Typography variant="body2">Encompass score: {npo.charityNavEncompassScore}%</Typography>
        {!!npo.charityNavEncompassStarRating && (
          <Box>
            <Typography variant="body2">Rating (out of 4)</Typography>
            <Rating value={npo.charityNavEncompassStarRating} max={4} readOnly />
          </Box>
        )}
      </Box>
    )}
  </Box>)
}
