import { Auth } from "@aws-amplify/auth"
import { isWizardEmail } from "@fieldday/fielddayportal-model"
import * as Sentry from "@sentry/react"
import dayjs from "dayjs"
import _ from "lodash"
import * as React from "react"
import { createContext, useContext, useState } from "react"
import { useHistory } from "react-router-dom"
import { UserHelper } from "../models/UserHelper"
import { UserInfo } from '../models/UserInfo'
import { useAPI } from '../util/useAPI'
import { orgKey } from "../util/userUtil"
import useInterval from "./useInterval"
import { AlertSeverity, useLoading } from "./useLoading"

/*
Hook for tracking auth status (via cognito) and UserInfo (via the FD API).
UserInfo is stored in localStorage so it persists across page refreshes.
*/

export const userInfoKey = "userInfo"
export const bearerTokenKey = "bearerToken"
export const cognitoNoUserError = "No current user"
const AuthContext = createContext<AuthForContext | undefined>(undefined)

export const teamInviteTokenName = "teamInviteToken"                        as const
export const teamInviteTokenAttr = `custom:${teamInviteTokenName}`          as const
export const teamInviteJoinEventName = "teamInviteJoinEvent"                as const
export const teamInviteJoinEventAttr = `custom:${teamInviteJoinEventName}`  as const
export const guestTokenName = "guestToken"                                  as const
export const guestTokenAttr = `custom:${guestTokenName}`                    as const
export const publicTeamName = "publicTeam"                                  as const
export const publicTeamAttr = `custom:${publicTeamName}`                    as const
export const campaignInviteTokenName = "campaignInviteToken"                as const
export const campaignInviteTokenAttr = `custom:${campaignInviteTokenName}`  as const
export const regionAttr = "region"                                          as const

interface AuthProps {
  children: React.ReactNode
}

declare global {
  interface Window {
    _cio: any
  }
}

interface AuthForContext {
  user: UserInfo | null
  handleSignOut: () => Promise<any>
  refreshUserNotifications: () => Promise<void>
  refreshUserProfile: () => Promise<UserInfo>
  isWizard: () => boolean
  guardUserComplete: () => void
  trackVisitInCIO: (page: string) => void
}

export function AuthProvider({ children }:AuthProps) {
  const auth = useProvideAuth()

  return (
    <AuthContext.Provider value={auth}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (!context) throw new Error("useAuth must be used within an AuthProvider")
  return context
}

function _setLocalUser(userInfo: UserInfo | null) {
  if (userInfo) {
    localStorage.setItem(userInfoKey, JSON.stringify(userInfo))
    Sentry.setUser({ id: userInfo.id })
  } else {
    localStorage.removeItem(userInfoKey)
    Sentry.setUser(null)
  }
}

function _getLocalUser() {
  const userFromLocal = localStorage.getItem(userInfoKey)
  return userFromLocal ? JSON.parse(userFromLocal) : null
}

// first time we load the script, include the user to identify
function loadCioSdk(userId?: string) {
  window._cio = []
  const a = function (f: string, ...args: any[]) {
    return function () {
      window._cio.push([f, ...args])
    }
  };
  const b = ["load", "identify", "sidentify", "track", "page", "on", "off"];
  for (let c = 0; c < b.length; c++) {
    window._cio[b[c]] = a(b[c])
  }
  const setUser = () => {
    window._cio?.identify({ id: userId })
  }
  const t = document.createElement('script')
  const s = document.getElementsByTagName('script')[0]
  t.async = true
  t.id = 'cio-tracker'
  t.setAttribute('data-site-id', import.meta.env.REACT_APP_PRODUCT_TOUR_ID)
  t.setAttribute('data-use-array-params', 'true')
  t.setAttribute('data-use-in-app', 'true')
  t.src = 'https://assets.customer.io/assets/track.js'
  t.addEventListener('load', setUser)
  s.parentNode?.insertBefore(t, s)
}

function useProvideAuth(): AuthForContext {
  const FieldDayAPI = useAPI()
  const [user, setUser] = useState<UserInfo | null>(_getLocalUser())
  const { setAlert } = useLoading()
  const history = useHistory()
  const refreshInterval = 10

  useInterval(() => {
    refreshUserNotifications()
  }, refreshInterval)

  const _updateUserProfile = (userInfo: UserInfo | null) => {
    if (userInfo) {
      const userHelper = new UserHelper(userInfo)
      userInfo.displayName = userHelper.displayName()
      userInfo.isComplete = userHelper.isComplete()

      if(!window._cio?.identify) {
        loadCioSdk(userInfo.id)
      } else {
        window._cio.identify({ id: userInfo.id })
      }
      /*
      const teamsUserOwns = userHelper.ownerOrgUnitMemberships()
        .map(mem => mem.orgUnit)
        .sort((a,b) => dayjs(b.createdAt).diff(dayjs(a.createdAt)))
      const nposUserOwns = userHelper.ownerNonprofitMemberships()
        .map(mem => mem.nonProfitOrg)
        .sort((a,b) => dayjs(b.createdAt).diff(dayjs(a.createdAt)))
      */
    }
    console.log("setting user info to ", userInfo)
    setUser(userInfo)
    _setLocalUser(userInfo)
  }

  const trackVisitInCIO = (page: string) => {
    if (window._cio && user) {
      window._cio.track("Platform visit", { page: page })
    }
  }

  const refreshUserNotifications = async () => {
    if (!user) return
    FieldDayAPI.getUnreadNotificationCount().then(resp => {
      const updateUser = Object.assign({}, user)
      updateUser.unreadNotificationCount = resp.unreadNotificationCount
      updateUser.latestNotification = resp.latestNotification
      if (!_.isEqual(user, updateUser)) {
        _updateUserProfile(updateUser)
      }
    }).catch(e => {
      const status = e.response?.status
      if (e === cognitoNoUserError || e.code === 'NotAuthorizedException' || status === 401) { // stale session, reset user profile
        _updateUserProfile(null)
      } else if (status !== 429 && status !== 503) {
        setAlert(AlertSeverity.WARNING, `Error contacting server. Please check your Internet connection and refresh the page. (${e})`, 60_000)
      }
    })
  }

  const refreshUserProfile = (): Promise<UserInfo> => {
    const promise: Promise<UserInfo> = FieldDayAPI.getUserProfile()
    promise.then((data: UserInfo) => {
      data.expiresAt = dayjs().add(5, 'minute')
      _updateUserProfile(data)
      setUser(data)
    }).catch((err) => {
      console.log("error during getUserProfile", err)
      if (err.response || err === cognitoNoUserError) { // if there was a non-success response, effectively log them out
        _updateUserProfile(null)
      } // otherwise, leave it alone because it's probably a network error and they might come back online
    })
    return promise
  }

  const handleSignOut = async (): Promise<any> => {
    console.log("deleting user info (sign out)")
    localStorage.removeItem(orgKey)
    const signOutResult = await Auth.signOut()
    _updateUserProfile(null)
    return signOutResult
  }

  const isWizard = (): boolean => {
    return isWizardEmail(user?.email)
  }

  const guardUserComplete = () => {
    if (!user?.isComplete) {
      setAlert(AlertSeverity.ERROR, "Complete your profile to continue")
      history.push("/profile/edit", { from: history.location })
    }
  }

  // trigger a reload if enough time has passed since the last load
  if (user && dayjs().isAfter(user.expiresAt)) {
    refreshUserProfile()
  }

  return { user, handleSignOut, refreshUserNotifications, refreshUserProfile, isWizard, guardUserComplete, trackVisitInCIO }
}


